
export default {
  mixins: [],
  props: {'kontakt': null, 'betreffender': null, startcall: {default: true}, sms: {default: false}},
  data() {
    return {}
  },
  computed: {
    // Blendet Detaildaten von Mitarbeitern aus, wenn man nicht die passende Permission hat
    displayDetails() {
      if (this.kontakt && this.kontakt.model_type.includes('Mitarbeiter') && !this.$can('Mitarbeiter verwalten')) return false;
      return true;
    }
  },
  watch: {},
  methods: {},
  mounted() {},

}
