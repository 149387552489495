export default {
  mixins: [],
  props: [],
  data() {
    return {
      filterSpeicher: null,
    }
  },
  computed: {
    buttonOptions() {
      if (!this.filterSpeicher) return;
      if (this.filterSpeicher == 'unvollstaendig') return {0: 'scan', 1: 'loeschen'};
      if (this.filterSpeicher == 'ungeprueft') return {'Prüfen & Details erfassen': 'bearbeiten', 1: 'loeschen'};
      if (this.filterSpeicher == 'aenderung_erforderlich') return {0: 'verordnungsaenderung', 1: 'loeschen', 2: 'in_therapie'};
      if (this.filterSpeicher == 'aenderung_laeuft') return {0: 'verordnungsaenderung', 1: 'loeschen', 2: 'scan', 3: 'aenderung_erledigt'};
      if (this.filterSpeicher == 'in_therapie') return {1: 'abrechnung'}
      if (this.filterSpeicher == 'in_abrechnung') return {0: 'verordnungsaenderung', 1: 'bearbeiten', 2: 'abrechnung'};
      if (this.filterSpeicher == 'abgerechnet') return {2: 'abrechnung'};
      if (this.filterSpeicher == 'abrechnung') return {0: 'bearbeiten', 'Abrechnungsübersicht': 'abrechnung', 2: 'scan'};
      if (this.filterSpeicher == 'alle') return {'Abrechnungsübersicht': 'abrechnung'};

      return {};
    }
  },
  watch: {},
  methods: {
    fill(filterInput) {
      this.filterSpeicher = filterInput;
      let filter = [];
      let append = ['last_activity'];
      let perPage = 10;
      let withAttribute = ['patient.kontakt'];
      if (filterInput == 'unvollstaendig') filter.push({where: ['status', 'unvollstaendig']});
      else if (filterInput == 'ungeprueft') filter.push({where: ['status', 'ungeprueft']});
      else if (filterInput == 'aenderung_erforderlich') {
        filter.push({where: ['status', 'aenderung_erforderlich']});
        append.push('nicht_anfangen');
        append.push('arztaenderung_noetig');
        withAttribute.push('aenderungen.schreiben.zuordnungen.model')
      }
      else if (filterInput == 'aenderung_laeuft') {
        filter.push({where: ['status', 'aenderung_laeuft']});
        withAttribute.push('aenderungen.schreiben')
      }
      else if (filterInput == 'in_therapie') filter.push({where: ['status', 'in_therapie']});
      else if (filterInput == 'in_abrechnung') {
        filter.push({where: ['status', 'in_abrechnung']});
        // withAttribute.push('therapiestunden');
      }
      else if (filterInput == 'abgerechnet') filter.push({where: ['status', 'abgerechnet']});

      else if (filterInput == 'aenderungoffen') filter.push({where: ['status', 'aenderungoffen']});
      else if (filterInput == 'voll') filter.push({where: ['status', 'voll']});

      else if (filterInput == 'abrechnung') {
        filter.push({where: ['abrechnung_id', this.abrechnung_id]});
      }
      else if (filterInput == 'alle') {
        filter = [];
        append = [];
        perPage = 5;
      }
      // if (this.filter == 3) filter.push({aenderungNoetig: []});
      this.$$fill('verordnungen', {with: withAttribute, searchable: {0: 'patient_id', 1: 'id', patient: {kontakt: ['vorname', 'nachname']}}, filter: filter, append: append, perPage: perPage})
    }
  },
  mounted() {},

}
