
import faelle from '~/mixins/faelle';
export default {
  mixins: [faelle],
  props: [],
  data() {
    return {
      fallkey: 0,
      alleAnrufe: false,
      geschlosseneAufgaben: false,
      meineAufgabenMitarbeiter: null,
      displaygeschlossen: false,
      page: 0,
      totals: {
        0: {gesamt: null, letzterEintrag: null},
        1: {gesamt: null, letzterEintrag: null},
        2: {gesamt: null, letzterEintrag: null},
        3: {gesamt: null, letzterEintrag: null},
        4: {gesamt: null, letzterEintrag: null},
        5: {gesamt: null, letzterEintrag: null},
        6: {gesamt: null, letzterEintrag: null}
      },
      allkey: 0,
    }
  },
  computed: {},
  watch: {},
  methods: {
    // totalsAbrufen() {
    //   for (let index = 0; index < 7; index++) {
    //     this.$axios.get()
    //     let params = this.datenAbrufParams(index, 1);
    //     params.orderBy = {'updated_at': 'desc'};
    //     this.$axios.get('/api/faelle', {params: params}).then(response => {
    //       this.totals[index].gesamt = response.data.total;
    //       if (response.data.total == 0) this.totals[index].letzterEintrag = null;
    //       else this.totals[index].letzterEintrag = response.data.data[0].updated_at;
    //     })
    //     setTimeout(() => {
    //       this.currentPageKey++;
    //     }, 1000)
    //   }
    // },
  },
  mounted() {
    this.meineAufgabenMitarbeiter = this.$model.id;
    // this.totalsAbrufen();
    // this.$nuxt.$on('socket.FallAktualisiertAlle', this.totalsAbrufen);
  },
  beforeDestroy() {
    // this.$nuxt.$off('socket.FallAktualisiertAlle', this.totalsAbrufen);
    // this.$nuxt.$off('update-fall', this.totalsAbrufen);
  }

}
